@import "src/styles/colors"
.footer
    display: flex
    justify-content: center
    min-height: 68px
    width: 100%
    border-top: 1px solid $gray-E7
    background-color: $gray-F5
    @media only screen and (max-width: 555px)
        min-height: 160px

.wrap
    display: flex
    justify-content: space-between
    align-items: center
    width: 80%
    @media only screen and (max-width: 555px)
        flex-direction: column
        gap: 10px
        margin-top: 20px

.leftSide
    display: flex
    gap: 30px
    @media only screen and (max-width: 555px)
        flex-direction: column
        align-items: center
        gap: 10px

.rightSide
    display: flex
    gap: 5px
    align-items: center
    cursor: pointer

.rightSideText
    font-size: 13px
    line-height: 16px
    color: $light-gray

.buttonRight
    width: 100px
    height: 38px
    padding: 11px 26px
    border-radius: 12px
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    font-size: 13px
    font-weight: 600
    line-height: 16px
    @media only screen and (max-width: 555px)
        width: 100%
        margin-bottom: 24px

.wrapLink
    display: flex
    justify-content: flex-end
    @media only screen and (max-width: 555px)
        width: 100%
        display: flex
        justify-content: center
