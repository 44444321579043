.category
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    width: 25%
    background-color: #F1F1F1
    padding: 20px 20px
    border-radius: 30px
    height: min-content

.currentCat
    display: flex
    align-items: flex-start
    width: 100%
    flex-direction: column
    gap: 20px

.titleCur
    font-size: 24px
    font-weight: 500
    line-height: 28.8px

.listCat
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 10px

.formAddCut
    display: flex
    align-items: flex-start
    width: 100%
    flex-direction: column
    gap: 20px

.addCut
    display: flex
    justify-content: center
    align-items: center
    color: white
    padding: 0px 40px
    height: 45px
    background-color: #FF007A
    border-radius: 12px

    font-size: 13px
    font-weight: 600
    line-height: 15px

.addSvg
    display: flex
    flex-direction: column
    align-items: center
    gap: 5px

    &Title
        margin-left: 20px
        font-size: 14px
        line-height: 14.3px

.hiddenChoseFile
    opacity: 0
    height: 0
    width: 0
    line-height: 0
    overflow: hidden
    padding: 0
    margin: 0

.buttonAdd
    cursor: pointer
    background-color: #D9D9D9
    border-radius: 10px
    padding: 16px
    display: flex
    flex-direction: row
    align-items: center
    gap: 10px
    height: 55px

    svg
        height: 24px
        width: 24px

    &Text
        font-size: 14px
        color: #666666

.filename
    margin-left: 20px
    margin-top: -20px
    color: #878787
    font-size: 14px
    line-height: 14.3px
    max-width: 150px
