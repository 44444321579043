.formGroup
    display: flex
    flex-direction: column
    gap: 20px

.hiddenChoseFile
    opacity: 0
    height: 0
    width: 0
    line-height: 0
    overflow: hidden
    padding: 0
    margin: 0

.buttonAdd
    margin-top: 10px
    cursor: pointer
    background-color: #D9D9D9
    border-radius: 10px
    padding: 16px
    display: flex
    flex-direction: row
    align-items: center
    gap: 10px
    height: 55px

    svg
        height: 24px
        width: 24px

    &Text
        font-size: 14px
        color: #666666

.title
    font-size: 13px
    line-height: 16.9px
    text-align: start
    color: #666666

.blockText
    display: flex
    flex-direction: column
    gap: 5px

    &Title
        font-size: 20px
        font-weight: 600
        line-height: 24px
