@import "reset"
@import "tipografy"

body
    min-height: 100vh
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available

html
    height: -webkit-fill-available
