@import "src/styles/colors"
.bottomText
  display: flex
  flex-direction: column
  align-items: center
  gap: 30px
  margin-top: 50px

.text
  font-size: 34px
  font-weight: 600
  line-height: 40px
  text-align: center
  @media only screen and (max-width: 768px)
    font-size: 18px
    font-weight: 700
    line-height: 20px

.bottomImg
  width: 191px
  height: 65px

.buttonBottom
  height: 50px
  padding: 10px 30px
  background-color: #FFE5F2
  display: flex
  align-items: center
  justify-content: center
  gap: 7px
  border-radius: 18px
  width: max-content
  svg
    width: 14px
    height: 17px
  svg path
    fill: $pink-FF

.buttonBottom > span
  font-size: 16px
  font-weight: 500
  line-height: 19px
  color: $pink-FF
  margin-top: 3px
  @media only screen and (max-width: 768px)
    font-size: 10px
    font-weight: 500
    line-height: 12px


