.about
  display: flex
  flex-direction: column
  gap: 30px
  opacity: 0
  animation: ani 1.5s forwards

.another
  display: flex
  flex-direction: column
  align-items: center
  gap: 40px
  margin-bottom: 100px

@keyframes ani
  0%
    opacity: 0
  100%
    opacity: 1
