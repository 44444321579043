.blockProduct
    display: grid
    grid-template-columns: repeat(4, 290px)
    grid-column-gap: 10px
    grid-row-gap: 20px

    @media only screen and (max-width: 1700px)
        grid-template-columns: repeat(3, 290px)

    @media only screen and (max-width: 1250px)
        grid-template-columns: repeat(2, 290px)

    @media only screen and (max-width: 850px)
        grid-template-columns: repeat(1, 290px)

.overlay
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(black, 0.6)

    display: flex
    justify-content: center
    align-items: center

    z-index: 10

.content
    position: absolute
    max-height: 100vh
    display: flex
    max-width: 700px
    overflow-y: hidden
