.blockHR
    width: 100%
    @media only screen and (max-width: 768px)
        display: none

.overlay
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(black, 0.6)

    display: flex
    justify-content: center
    align-items: center

    z-index: 10

.content
    position: absolute
    outline: none
    max-height: 100vh
    display: flex
    max-width: 700px

.wrapper
    background-color: white
    overflow: auto
    width: 500px
    border-radius: 50px
    padding: 50px
    justify-content: center

.wrapperInner
    display: flex
    flex-direction: column
    gap: 10px
    min-width: 400px
    background-color: #F5F5F5
    padding: 30px
    border-radius: 24px
    width: 50%

.buy
    margin-top: 20px
    display: flex
    width: 340px
    justify-content: center
    align-items: center
    color: white
    padding: 0px 40px
    height: 45px
    background-color: #FF007A
    border-radius: 12px

    font-size: 13px
    font-weight: 600
    line-height: 15px
