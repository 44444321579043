@import "src/styles/colors"
.dollar
    font-size: 14px
    line-height: 17px
    color: $black-76

.wrapInput
    background-color: rgba(black, 0.04)
    padding: 10px
    border-radius: 10px
    width: 100%
    display: flex
    align-items: center

.wrapWhite
    background-color: white
    padding: 10px
    border-radius: 10px
    width: 100%
    display: flex
    align-items: center

.customInput
    caret-color: $pink-FF
    width: 100%
    @media only screen and (max-width: 425px)
        width: 100%
        font-size: 14px
