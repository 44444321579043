@import "src/styles/colors"
.platform
    padding: 7px
    background-color: $gray-F5
    display: flex
    align-items: center
    gap: 7px
    border-radius: 10px
    width: max-content
    cursor: pointer
    transition-duration: 0.5s

.platform:hover
    background-color: $light-pink
    .name
        color: $pink-FF
        svg path
        fill: $pink-FF

.name
    font-size: 14px
    line-height: 17px
    color: $light-gray
    @media only screen and (max-width: 430px)
        font-size: 10px
        line-height: 12px

.platform svg
    width: 17px
    height: 15px
    path
        fill: $light-gray
