.adminStore
  display: flex
  gap: 20px
  padding: 20px 0

.mainContent
  display: flex
  flex-direction: column
  gap: 20px
  width: 80%
