@import "../../styles/colors"
.cardProduct
    background-color: white
    padding: 25px
    display: flex
    flex-direction: column
    gap: 20px
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
    border-radius: 25px
    @media only screen and (max-width: 768px)
        width: 100%

.topBlock
    display: flex
    justify-content: space-between
    align-items: flex-start

.imgCard
    width: 65px
    height: 65px

.wrapEdit
    display: flex
    gap: 8px

    button
        cursor: pointer

.countProduct
    display: flex
    align-items: center
    gap: 10px

.countProduct button
    display: flex
    align-items: center
    height: 20px

.countNumber
    height: 32px
    width: 26px
    background-color: $gray-F5
    border-radius: 9px
    display: flex
    align-items: center
    justify-content: center

.countNumber span
    font-size: 15px
    line-height: 18px
    color: $light-gray

.middleBlock
    display: flex
    flex-direction: column
    flex-grow: 1
    gap: 5px

.nameApp
    font-size: 18px
    font-weight: 700
    line-height: 20px

.descList

.normal, .bold
    font-size: 13px
    line-height: 17px

.normal
    color: $light-gray
