.blockDesignProduct
    display: flex
    flex-direction: column
    gap: 20px
    margin-bottom: 20px

.listCards
    display: flex
    justify-content: flex-start
    gap: 32px
    flex-wrap: wrap
    @media only screen and (max-width: 1500px)
        justify-content: flex-start
    @media only screen and (max-width: 1000px)
        justify-content: center
