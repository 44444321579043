@import "src/styles/colors"
.blackPart
  min-height: 1100px
  background-image: url("./about.png")
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
  background-color: black
  width: 100%
  display: flex
  justify-content: center
  @media only screen and (max-width: 768px)
    min-height: 700px

.content
  display: flex
  flex-direction: column
  align-items: center
  gap: 150px
  margin-top: 150px
  @media only screen and (max-width: 768px)
    gap: 50px
    margin-top: 50px

.logoAndName
  display: flex
  flex-direction: column
  align-items: center
  gap: 50px
  @media only screen and (max-width: 768px)
    gap: 10px

.logoAndName > img
  width: 100px
  height: 100px

.title
  font-size: 45px
  font-weight: 600
  line-height: 44px
  color: white
  text-align: center
  width: 75%
  @media only screen and (max-width: 768px)
    font-size: 23px
    font-weight: 600
    line-height: 23px
    text-align: center
    width: 100%

.desc
  display: none
  @media only screen and (max-width: 768px)
    display: flex
    font-size: 14px
    line-height: 17px
    text-align: center
    color: white
    opacity: .4
    width: 100%

.blockButton
  display: flex
  gap: 10px
  @media only screen and (max-width: 768px)
    order: 2
    flex-direction: column
    width: 100%


.buttonAbout, .buttonOrder
  display: flex
  align-items: center
  gap: 5px
  height: 55px
  padding: 15px
  background-color: white
  border-radius: 18px
  opacity: 30%
  svg
    width: 16px
    height: 17px
  svg path
    fill: #767676
  @media only screen and (max-width: 768px)
    justify-content: center

.buttonAbout > span, .buttonOrder > span
  font-size: 16px
  font-weight: 500
  line-height: 19px

.buttonAbout > span
  color: #767676

.buttonOrder > span
  color: black

.buttonOrder
  svg path
    fill: black

.buttonOrder
  opacity: 100%

.listBlockService
  display: flex
  gap: 15px
  @media only screen and (max-width: 1100px)
    flex-wrap: wrap
    justify-content: center
    gap: 10px



















