.buttonAdd
    min-height: 300px
    width: 290px
    background-color: #F2F0F0
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 30px
    border-radius: 25px
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1)

    &Big
        height: 519px
