@import "../../styles/colors"
.designCard
    background-color: white
    padding: 25px
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 20px
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
    border-radius: 25px
    @media only screen and (max-width: 768px)
        width: 80%
    @media only screen and (max-width: 425px)
        width: 100%

.wrap, .wrapButton
    display: flex
    flex-direction: column
    gap: 10px

.editButton
    display: flex
    justify-content: flex-end
    gap: 8px

    button
        cursor: pointer

.middleBlock
    display: flex
    flex-direction: column
    gap: 5px

.blockText
    display: flex
    flex-direction: column
    gap: 5px

.nameDesign
    font-size: 18px
    font-weight: 700
    line-height: 20px

.link
    color: blue
    text-decoration: underline
    font-size: 13px
    line-height: 16.9px

.description
    margin-top: 8px

.normal, .bold
    font-size: 13px
    line-height: 17px

.normal
    color: $light-gray
