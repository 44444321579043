@import "../../styles/colors"
.cardApp
    display: flex
    justify-content: space-between
    padding: 20px
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: 30px
    overflow: hidden
    min-height: 410px
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
    position: relative
    @media only screen and (max-width: 768px)
        min-height: 485px
        gap: 20px

    &Slider
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
        @media only screen and (max-width: 1000px)
            min-height: 485px
            gap: 20px

.leftSide
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 40%
    @media only screen and (max-width: 768px)
        width: 100%
        gap: 20px

    &Slider
        @media only screen and (max-width: 1000px)
            width: 100%
            gap: 20px

.wrappTop
    display: flex
    flex-direction: column
    gap: 20px

.topText
    display: flex
    flex-direction: column

.category
    font-size: 18px
    font-weight: 500
    line-height: 20px
    color: $pink-FF
    @media only screen and (max-width: 430px)
        font-size: 12px
        font-weight: 700
        line-height: 16px

.titleApp
    font-size: 30px
    font-weight: 600
    line-height: 33px
    text-align: left
    @media only screen and (max-width: 430px)
        font-size: 18px
        font-weight: 700
        line-height: 20px

.listPlatform
    display: flex
    gap: 10px

.descCard
    font-size: 16px
    font-weight: 500
    line-height: 19px
    color: $light-gray
    @media only screen and (max-width: 430px)
        font-size: 11px
        font-weight: 500
        line-height: 13px

.buttonPay
    min-height: 55px
    padding: 10px 30px
    background-color: $gray-F5
    display: flex
    align-items: center
    justify-content: center
    gap: 7px
    border-radius: 18px
    width: max-content

    @media only screen and (max-width: 768px)
        width: 100%

svg
    width: 14px
    height: 17px
    @media only screen and (max-width: 430px)
        width: 10px
        height: 10px
    svg path
        fill: $pink-FF
        @media only screen and (max-width: 768px)
            width: 100%
        @media only screen and (max-width: 430px)
            min-height: 44px

    &Slider
        @media only screen and (max-width: 1000px)
            width: 100%

.buttonPay > span
    font-size: 16px
    font-weight: 500
    line-height: 19px
    color: $pink-FF
    margin-top: 3px
    @media only screen and (max-width: 430px)
        font-size: 10px
        font-weight: 500
        line-height: 12px

.imgApp
    position: absolute
    bottom: 0
    right: -44px
    @media only screen and (max-width: 850px)
        width: 60%
    @media only screen and (max-width: 768px)
        display: none
    @media only screen and (max-width: 600px)
        width: 100%
        position: static

    &Slider
        @media only screen and (max-width: 1000px)
            display: none

.imgMobile
    display: none
    @media only screen and (max-width: 768px)
        display: flex

    &Slider
        display: none
        @media only screen and (max-width: 1000px)
            display: flex
