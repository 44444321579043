@import "../../styles/colors"
.blockTitle
    position: relative
    @media only screen and (max-width: 768px)
        display: flex
        justify-content: center

    &Center
        display: flex
        justify-content: center
        text-align: center

.block
    height: max-content
    width: max-content
    border-radius: 17px
    padding: 6px 17px 8px 14px
    background-color: $gray-F5
    display: flex
    align-items: center
    gap: 5px
    @media only screen and (max-width: 768px)
        justify-content: center
    @media only screen and (max-width: 426px)
        width: max-content

    &Center
        justify-content: center

.block
    display: flex
    justify-content: center
    align-items: center
    gap: 6px
    svg, img
        width: 19px
        height: 22px
        @media only screen and (max-width: 426px)
            width: 10px
            height: 12px
    svg path
            fill: $pink-FF

.text
    font-weight: 500
    font-size: 24px
    line-height: 28px
    @media only screen and (max-width: 426px)
        font-size: 14px
        margin-top: 0

.wrapHR
    position: absolute
    width: 100%
    top: 15px
    z-index: -1
