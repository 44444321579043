.app
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  overflow-x: hidden
  height: 100vh
  justify-content: space-between

.mainWrapp
  width: 95%
  display: flex
  flex-direction: column
  @media only screen and (max-width: 950px)
    width: 90%

  &IsAbout
    width: 100%




