@import "../../../../styles/colors"
.linkBlock
  display: flex
  align-items: center
  height: 35px
  padding: 7px 11px
  gap: 9px
  border-radius: 9px
  cursor: pointer
  @media only screen and (max-width: 820px)
    background-color: $gray-FC
    height: 55px

.linkBlockActive
  background-color: $pink-FFE
  .namePage
    color: $pink-FF
  svg path
    fill: $pink-FF

.linkBlockIsAbout
  .namePage
    color: $gray-FA
    @media only screen and (max-width: 768px)
      color: $light-gray
  @media only screen and (max-width: 820px)
    background-color: black
    height: 55px
  @media only screen and (max-width: 768px)
    background-color: $gray-FC
    height: 55px
  svg path
    fill: $gray-FA
    @media only screen and (max-width: 768px)
      fill: $light-gray

.linkBlockIsAboutActive
  background-color: $black-22
  @media only screen and (max-width: 768px)
    background-color: $pink-FFE
  svg path
    fill: white
    @media only screen and (max-width: 768px)
      fill: $pink-FF
  .namePage
    @media only screen and (max-width: 768px)
      color: $pink-FF

.linkBlock:hover
  background-color: $gray-F5

.linkBlockIsAbout:hover
  background-color: $black-22
  @media only screen and (max-width: 768px)
    background-color: $gray-FC

.linkBlock:hover .namePage
  color: $pink-FF

.linkBlock:hover
  svg path
    fill: $pink-FF

.linkBlockIsAbout:hover .namePage
  color: $gray-FA
  @media only screen and (max-width: 820px)
    color: $pink-FF

.linkBlockIsAbout:hover svg path
  fill: $gray-FA
  @media only screen and (max-width: 820px)
    fill: $pink-FF

.namePage
  font-size: 13px
  line-height: 16px
  color: $light-gray

.stroke
  svg path
    fill: none

.linkBlock:hover .stroke
  svg path
    fill: none
    stroke: $pink-FF

.linkBlockActive .stroke
  svg path
    fill: none
    stroke: $pink-FF

.linkBlockIsAbout .stroke, .linkBlockIsAbout:hover .stroke
  svg path
    fill: none
    stroke: $light-gray

