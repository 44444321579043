@import "src/styles/colors"
.aboutCompany
    min-height: 175px
    display: flex
    position: relative
    @media only screen and (max-width: 768px)
        display: none

.imgCompany
    width: 100%
    border-radius: 25px

.leftSide
    display: flex
    flex-direction: column
    gap: 5px
    position: absolute
    left: 40px
    bottom: 40px

.title
    color: $light-gray
    font-weight: 600
    font-size: 14px
    line-height: 17px

.desc
    color: white
    font-weight: 600
    font-size: 18px
    line-height: 20px

.navButton
    position: absolute
    bottom: 30px
    right: 40px
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    height: 43px
    padding: 14px 18px
    border-radius: 14px
    background-color: $pink-FFE
    svg
        width: 11px

    svg path
        fill: $pink-FF

.textButton
    font-size: 12px
    font-weight: 500
    line-height: 15px
    color: $pink-FF
