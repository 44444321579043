@import "src/styles/colors"
.menu__btn2, .menu__fixed
    display: none
    @media only screen and (max-width: 768px)
        display: flex
        align-items: center
        justify-content: center
        top: 20px
        width: 33px
        height: 33px
        cursor: pointer
        border-radius: 9px
        z-index: 1

.menu__fixed
    background-color: $pink-FF
    position: fixed
    left: 5%
    bottom: 20px
    top: auto
    z-index: 9

    @media only screen and (max-width: 768px)
        left: 3%

    @supports (selector(:nth-child(1 of x))) and (-webkit-touch-callout: none)
        bottom: (20px + env(safe-area-inset-bottom))

.menu__btn2
    background-color: $light-pink
    position: absolute
    right: 0

.menu__btn2 > span,
.menu__btn2 > span::before,
.menu__btn2 > span::after,
.menu__fixed > span,
.menu__fixed > span::before,
.menu__fixed > span::after
    display: flex
    position: absolute
    height: 1.5px
    width: 50%
    transition-duration: .25s

.menu__btn2 > span,
.menu__btn2 > span::before,
.menu__btn2 > span::after
    background-color: $pink-FF

.menu__fixed > span,
.menu__fixed > span::before,
.menu__fixed > span::after
    background-color: white

.menu__btn2 > span::before, .menu__fixed > span::before
    content: ''
    top: -6px
    width: 10px

.menu__btn2 > span::after, .menu__fixed > span::after
    content: ''
    top: 6px
    width: 14px

.close__menu > span
    transform: rotate(45deg)
    width: 14px

.close__menu > span::before
    top: 0
    transform: rotate(0)

.close__menu > span::after
    top: 0
    transform: rotate(90deg)

.IsAbout
    background-color: $black-20

.IsAbout > span,
.IsAbout > span::before,
.IsAbout > span::after
    background-color: white
