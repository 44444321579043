@import "../../styles/colors"
.auth
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 40px
    width: 40%

.wrapForm
    display: flex
    width: 100%
    justify-content: center

.title
    font-size: 45px
    font-weight: 500
    line-height: 49.5px

.buttonAuth
    display: flex
    align-items: center
    justify-content: center
    height: 55px
    width: 260px
    background-color: #F5F5F5
    border-radius: 18px
    color: #FF007A
    font-size: 15.6px
    font-weight: 500
    line-height: 18.88px

    &Faded
        color: $black-7b

.error
    margin-top: 12px
    color: #FF007A

.inputWrap
    width: 360px
