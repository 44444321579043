@import "../../styles/colors"
.cardApp
  display: flex
  justify-content: space-between
  padding: 20px
  border: 1px solid rgba(0, 0, 0, 0.1)
  border-radius: 30px
  min-height: 485px
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
  position: relative
  gap: 20px
  width: 290px
  background-color: white

  &Slider
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
    @media only screen and (max-width: 1000px)
      min-height: 485px
      gap: 20px

.leftSide
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  gap: 20px

  &Slider
    @media only screen and (max-width: 1000px)
      width: 100%
      gap: 20px

.wrappTop
  display: flex
  flex-direction: column
  gap: 20px

.topText
  display: flex
  flex-direction: column

.category
  font-size: 12px
  font-weight: 700
  line-height: 16px
  color: $pink-FF


.titleApp
  font-size: 18px
  font-weight: 700
  line-height: 20px
  text-align: left

.listPlatform
  display: flex
  gap: 10px

.descCard
  font-size: 11px
  font-weight: 500
  line-height: 13px
  color: $light-gray

.buttonPay
  min-height: 44px
  padding: 10px 30px
  background-color: $gray-F5
  display: flex
  align-items: center
  justify-content: center
  gap: 7px
  border-radius: 18px
  width: 100%
  svg
    width: 10px
    height: 10px
  svg path
    fill: $pink-FF

  &Slider
    @media only screen and (max-width: 1000px)
      width: 100%

.buttonPay > span
  font-size: 10px
  font-weight: 500
  line-height: 12px
  color: $pink-FF
  margin-top: 3px


.imgApp
  position: absolute
  bottom: 0
  right: -44px
  width: 60%
  display: none

  &Slider
    @media only screen and (max-width: 1000px)
      display: none

.imgMobile
  display: flex

  &Slider
    display: none
    @media only screen and (max-width: 1000px)
      display: flex









