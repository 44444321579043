@import "src/styles/colors"
.newTeam
  display: flex
  flex-direction: column
  gap: 20px

.imgTeam
  width: 100%
  border-radius: 30px 30px 0 0

.descWork
  display: flex
  flex-direction: column
  align-items: center
  border-radius: 30px
  border: rgba(black, 0.05) solid 1px
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1)

.textContent
  display: flex
  justify-content: space-between
  align-items: center
  background-color: white
  height: 150px
  width: 100%
  padding: 20px
  border-radius: 30px
  border: white solid 1px
  @media only screen and (max-width: 768px)
    flex-direction: column
    gap: 10px
    height: max-content
  
.textContentLeft
  display: flex
  flex-direction: column
  gap: 10px
  width: 50%
  @media only screen and (max-width: 768px)
    width: 100%

.titleLeft
  font-size: 30px
  font-weight: 600
  line-height: 33px
  @media only screen and (max-width: 1100px)
    font-size: 18px
    font-weight: 700
    line-height: 20px

.descLeft
  font-size: 16px
  font-weight: 500
  line-height: 19px
  opacity: 50%
  @media only screen and (max-width: 1100px)
    font-size: 11px
    font-weight: 500
    line-height: 13px

.buttonPay
  height: 50px
  padding: 10px 30px
  background-color: $gray-F5
  display: flex
  align-items: center
  justify-content: center
  gap: 7px
  border-radius: 18px
  width: max-content
  @media only screen and (max-width: 1100px)
    height: 40px
  @media only screen and (max-width: 768px)
    width: 100%
  svg
    width: 14px
    height: 17px
    @media only screen and (max-width: 1100px)
      width: 10px
      height: 10px
  svg path
    fill: $pink-FF

.buttonPay > span
  font-size: 16px
  font-weight: 500
  line-height: 19px
  color: $pink-FF
  margin-top: 3px
  @media only screen and (max-width: 1100px)
    font-size: 10px
    font-weight: 500
    line-height: 12px
    margin-top: 1px


