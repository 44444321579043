@import "../../styles/colors"
.cardProduct
    width: 31%
    background-color: white
    padding: 25px
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 20px
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
    border-radius: 25px
    @media only screen and (max-width: 1405px)
        width: 30%
    @media only screen and (max-width: 1150px)
        width: 47%
    @media only screen and (max-width: 768px)
        width: 100%

.topBlock
    display: flex
    flex-direction: column
    gap: 20px
    justify-content: space-between

.imgCard
    width: 65px
    height: 65px
    @media only screen and (max-width: 600px)
        width: 45px
        height: 45px

.countProduct
    display: flex
    align-items: center
    gap: 10px

.countProduct button
    display: flex
    align-items: center
    height: 20px

.countNumber
    height: 32px
    width: 26px
    background-color: $gray-F5
    border-radius: 9px
    display: flex
    align-items: center
    justify-content: center

.countNumber span
    font-size: 15px
    line-height: 18px
    color: $light-gray

.middleBlock
    display: flex
    flex-direction: column
    justify-content: flex-end
    gap: 5px

.nameApp
    font-size: 18px
    font-weight: 700
    line-height: 20px

    @media only screen and (max-width: 600px)
        font-size: 13px
        line-height: 14.3px

.descList

.normal, .bold
    font-size: 13px
    line-height: 17px

    @media only screen and (max-width: 600px)
        font-size: 12px
        line-height: 11.7px

.normal
    color: $light-gray

.errors
    margin-top: 12px
    color: $pink-FF

.overlay
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(black, 0.6)

    display: flex
    justify-content: center
    align-items: center

    z-index: 10

.content
    position: absolute
    max-height: 100vh
    display: flex
    max-width: 700px

    @media only screen and (max-width: 600px)
        max-width: 350px

.wrapper
    background-color: white
    overflow: auto
    width: 500px
    border-radius: 50px
    padding: 50px
    justify-content: center

    @media only screen and (max-width: 600px)
        width: 284px
        padding: 20px

.wrapperInner
    display: flex
    flex-direction: column
    gap: 10px
    min-width: 400px
    background-color: #F5F5F5
    padding: 30px
    border-radius: 24px
    width: 50%

    @media only screen and (max-width: 600px)
        min-width: 244px
        padding: 20px

.buy
    margin-top: 20px
    display: flex
    width: 340px
    justify-content: center
    align-items: center
    color: white
    padding: 0px 40px
    height: 45px
    background-color: #FF007A
    border-radius: 12px

    font-size: 13px
    font-weight: 600
    line-height: 15px

    @media only screen and (max-width: 600px)
        width: 100%
        height: 38px
        font-size: 10px
        line-height: 12px

    &Faded
        background-color: #ea73ad
