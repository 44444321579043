.wrapper
    background-color: white
    overflow: auto
    max-width: 600px
    border-radius: 50px
    padding: 50px
    justify-content: center

.leftSide
    display: flex
    flex-direction: column
    gap: 10px
    min-width: 450px
    background-color: #F5F5F5
    padding: 30px
    border-radius: 24px
    width: 50%

.rightSide
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%

.title
    font-size: 30px
    font-weight: 600
    line-height: 33px

.catProduct
    display: flex
    flex-direction: column
    gap: 5px
    &Title
        font-size: 18px
        font-weight: 500
        line-height: 19.8px
        text-align: left
        color: #313144

.previewBlock
    display: flex
    flex-direction: column
    gap: 10px

.titlePreview
    font-size: 18px
    font-weight: 700
    line-height: 19.8px
    text-align: left

.preview
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 276px
    height: 283px
    border-radius: 25px
    border: 1px solid black
    padding: 10px

.prevImg
    width: 60px
    height: 60px

.prevButton
    width: 100%
    height: 45px
    background-color: #F5F5F5
    border-radius: 12px
    font-size: 13px
    font-weight: 600
    line-height: 15px
