.categories
    display: grid
    grid-template-columns: repeat(6, 188px)
    grid-column-gap: 10px
    grid-row-gap: 10px

    @media only screen and (max-width: 1800px)
        grid-template-columns: repeat(5, 188px)

    @media only screen and (max-width: 1500px)
        grid-template-columns: repeat(4, 188px)

    @media only screen and (max-width: 1200px)
        grid-template-columns: repeat(3, 188px)

    @media only screen and (max-width: 600px)
        grid-template-columns: repeat(3, 1fr)

    @media only screen and (max-width: 430px)
        grid-template-columns: repeat(3, 1fr)
