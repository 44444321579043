@import "../../styles/colors"
.header
  display: flex
  justify-content: center
  width: 100%
  border-bottom: 1px solid $gray-E7
  padding: 20px 0
  @media only screen and (max-width: 768px)
    border-bottom: none

.aboutHeader
  background-color: black
  border-bottom: 1px solid black

.wrap
  display: flex
  justify-content: space-between
  align-items: center
  width: 80%
  @media only screen and (max-width: 950px)
    width: 90%
  position: relative

.leftSide
  display: flex
  gap: 30px
  @media only screen and (max-width: 820px)
    gap: 15px

.rightSide
  display: flex
  gap: 5px
  align-items: center
  &IsAbout
    svg path
      fill: white
  @media only screen and (max-width: 768px)
    display: none

.wrapLink
  display: flex
  gap: 5px
  align-items: center

.rightSideText
  font-size: 13px
  line-height: 16px
  color: $light-gray
  &IsAbout
    color: white

//////////////
.menu__box
  display: flex
  flex-direction: column
  gap: 10px
  position: fixed
  z-index: 10
  top: 70px
  right: -120%
  margin: 0
  width: 90%
  height: 100vh
  background-color: white
  border-radius: 20px
  list-style: none
  text-align: center
  padding: 20px
  @media only screen and (max-width: 400px)
    right: -110%

.menu__box
  transition-duration: .25s

.open
  right: 5%

.close
  right: -120%

////////

.bottomBlock
  display: flex
  flex-direction: column
  gap: 50px
  width: 100%
  margin-top: 50px

.linkToManager
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 3px

.title
  font-size: 11px
  line-height: 12px
  color: $light-gray

.telegramSide
  height: 55px
  background-color: $blue-E3
  display: flex
  width: 100%
  align-items: center
  padding: 0 20px
  border-radius: 13px
  gap: 10px


.telegramSide > svg path
  fill: $blue-4C

.telegramSideText
  font-size: 13px
  line-height: 16px
  color: $blue-4B


.closeButton
  display: flex
  justify-content: center
  align-items: center
  height: 55px
  width: 100%
  gap: 5px
  background-color: $gray-FC
  border-radius: 13px

.closeButton > span
  font-size: 13px
  line-height: 16px
  color: $light-gray

.hamburgerMenu
  display: none
  @media only screen and (max-width: 768px)
    display: block













