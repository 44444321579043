.ourProducts
  display: flex
  flex-direction: column
  gap: 20px

.listProducts
  display: flex
  gap: 10px
  justify-content: center
  flex-wrap: wrap
  @media only screen and (max-width: 1500px)
    justify-content: center
  @media only screen and (max-width: 768px)
    gap: 16px