@import "../../styles/colors"
.designCard
    width: 30%
    background-color: white
    padding: 25px
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 20px
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05)
    border-radius: 25px
    @media only screen and (max-width: 1500px)
        width: 30%
    @media only screen and (max-width: 1000px)
        width: 40%
    @media only screen and (max-width: 768px)
        width: 80%
    @media only screen and (max-width: 425px)
        width: 100%

.wrap, .wrapButton
    display: flex
    flex-direction: column
    gap: 20px

    img
        border-radius: 20px

.middleBlock
    display: flex
    flex-direction: column
    gap: 5px

.nameDesign
    font-size: 18px
    font-weight: 700
    line-height: 20px

    @media only screen and (max-width: 600px)
        font-size: 13px
        line-height: 14.3px

.link
    font-size: 13px
    line-height: 17px
    color: blue
    text-decoration: underline
    @media only screen and (max-width: 600px)
        font-size: 12px
        line-height: 11.7px

.overlay
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(black, 0.6)

    display: flex
    justify-content: center
    align-items: center

    z-index: 10

.content
    position: absolute
    max-height: 100vh
    display: flex
    max-width: 700px

.wrapper
    background-color: white
    overflow: auto
    width: 500px
    border-radius: 50px
    padding: 50px
    justify-content: center

.wrapperInner
    display: flex
    flex-direction: column
    gap: 10px
    min-width: 400px
    background-color: #F5F5F5
    padding: 30px
    border-radius: 24px
    width: 50%

.buy
    margin-top: 20px
    display: flex
    width: 340px
    justify-content: center
    align-items: center
    color: white
    padding: 0px 40px
    height: 45px
    background-color: #FF007A
    border-radius: 12px

    font-size: 13px
    font-weight: 600
    line-height: 15px

.normal, .bold
    font-size: 13px
    line-height: 17px
    @media only screen and (max-width: 600px)
        font-size: 12px
        line-height: 11.7px

.normal
    color: $light-gray
