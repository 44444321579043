.wrapMainContent
    width: 100%
    background-color: #F5F5F5
    padding: 20px
    border-radius: 25px

.wrap
    display: flex
    flex-direction: column
    gap: 30px

.wrapEditBlock
    display: flex
    justify-content: space-between
    align-items: center

.wrapEdit
    cursor: pointer

.title
    font-size: 30px
    font-weight: 500
    line-height: 33px
    text-align: left

.buttons
    display: flex
    gap: 24px
