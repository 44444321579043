.listApps
  display: flex
  flex-wrap: wrap
  gap: 20px

.overlay
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  background-color: rgba(black, 0.6)

  display: flex
  justify-content: center
  align-items: center

  z-index: 10