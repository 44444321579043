.wrapperCustomSelect
  position: relative
  width: 100%
  cursor: pointer

.customSelect
  padding: 12px 16px
  border: none
  outline: none
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25)
  font-size: 16px
  line-height: 19.2px
  appearance: none
  border-radius: 10px
  width: 100%
  cursor: pointer

.arrowSelect
  position: absolute
  top: 10px
  right: 10px

.open
  svg
    animation: open-button-animate 1s ease alternate
    transform: rotate(180deg)

.close
  svg
    animation: close-button-animate 1s ease alternate
    transform: rotate(0deg)


@keyframes open-button-animate
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(180deg)

@keyframes close-button-animate
  0%
    transform: rotate(180deg)

  100%
    transform: rotate(0deg)