.mainWrapperForPage
  display: flex
  flex-direction: column
  gap: 20px
  padding-bottom: 20px
  opacity: 0
  animation: ani 1.5s forwards

@keyframes ani
  0%
    opacity: 0
  100%
    opacity: 1