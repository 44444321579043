.nameCat
    background-color: #FFFFFF
    padding: 8px
    cursor: pointer
    border-radius: 8px
    display: inline
    align-items: center
    justify-content: space-between
    gap: 5px

    &:hover
        background-color: lightgray

.name
    font-size: 18px
    line-height: 19.8px

.icon
    height: 18px
    width: 18px

.beforeButton
    display: flex
    gap: 6px
