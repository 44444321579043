$light-gray: #878787
$gray-E7: #E7E7E7
$gray-F5: #F5F5F5
$gray-FC: #FCFCFC
$gray-FA: #FAFAFA
$gray-F3: #F3F3F3

$light-pink: #FFE6F2
$pink-FF: #FF007A
$pink-FFE: #FFE6F2

$blue-1C: #1C2140
$blue-E3: #E3F8FF
$blue-4B: #4BA1E3
$blue-4C: #4CA3E4
$blue-FB: #808CFB

$black-22: #222225
$black-20: #202021
$black-75: #757575
$black-7b: #7B7B7B
$black-76: #767676
$black-8F: #8F8F8F
$black-80: #808080