@import "../../styles/colors"

.titleBlock
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 30px
  @media only screen and (max-width: 600px)
    flex-direction: column
    gap: 15px

.leftSide
  display: flex
  flex-direction: column
  gap: 10px
  @media only screen and (max-width: 950px)
    width: 60%
  @media only screen and (max-width: 600px)
    width: 100%

.boldTitle
  font-weight: 600
  font-size: 62px
  line-height: 74.4px
  @media only screen and (max-width: 1250px)
    line-height: 60px
  @media only screen and (max-width: 950px)
    font-size: 45px
    line-height: 50px
  @media only screen and (max-width: 624px)
    font-size: 32px
    line-height: 42px

.desc
  font-size: 16px
  line-height: 17px
  color: $light-gray
  text-align: left
  width: 60%
  @media only screen and (max-width: 600px)
    font-size: 11px
    line-height: 13px

.rightBlock
  width: 35%
  @media only screen and (max-width: 600px)
    width: 100%
    display: flex
    justify-content: flex-end

.rightSide
  font-family: Neucha, serif
  font-size: 20px
  line-height: 24px
  text-align: right
  color: $light-gray
  width: 35%
  @media only screen and (max-width: 600px)
    width: 80%
    font-size: 14px
    line-height: 17px


