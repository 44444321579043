@import "src/styles/colors"
.card, .firstCard
    height: 145px
    border-radius: 17px
    padding: 20px
    display: flex
    flex-direction: column
    justify-content: space-between
    cursor: pointer
    @media only screen and (max-width: 600px)
        height: 120px
    @media only screen and (max-width: 430px)
        height: 72px
        width: 94px
        padding: 10px

.firstCard
    background-color: $pink-FFE
.title
    color: $pink-FF
    svg path
    fill: $pink-FF

.card
    background-color: $gray-F5

.card:hover
    background-color: $pink-FFE
.title
    color: $pink-FF
    svg path
    fill: $pink-FF

.title
    width: 65%
    font-size: 15px
    line-height: 17px
    color: $blue-1C
    @media only screen and (max-width: 600px)
        width: 100%
        font-size: 9px
        line-height: 10.35px

.icon
    height: 48px
    width: 48px
    @media only screen and (max-width: 600px)
        width: 16px
        height: 16px

svg
    @media only screen and (max-width: 600px)
        width: 15px
        height: 17px
