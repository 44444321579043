@import "../../../styles/colors"
.logoBlock
  display: flex
  align-items: center
  gap: 5px

.nameStore
  margin-top: 5px
  font-weight: bold
  font-size: 20px

.whiteNameStore
  color: $gray-FA

.footerLogo
  color: black

.logoBlock > img
  width: 37px
  height: 37px