@font-face
    font-family: 'Inter'
    src: url("../assets/fonts/Golos/GolosText-Regular.ttf")
    font-weight: 400
    font-style: normal

@font-face
    font-family: 'Inter'
    src: url("../assets/fonts/Golos/GolosText-Medium.ttf")
    font-weight: 500
    font-style: normal

@font-face
    font-family: 'Inter'
    src: url("../assets/fonts/Golos/GolosText-SemiBold.ttf")
    font-weight: 600
    font-style: normal

@font-face
    font-family: 'Inter'
    src: url("../assets/fonts/Golos/GolosText-Bold.ttf")
    font-weight: 700
    font-style: normal

@font-face
    font-family: 'Neucha'
    src: url("../assets/fonts/Neucha/Neucha-Regular.ttf")
    font-weight: 400
    font-style: normal

body
    font-family: Inter, serif
    font-style: normal
