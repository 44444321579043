.blockService
  display: flex
  flex-direction: column
  gap: 20px
  padding: 10px 20px
  border-radius: 25px
  width: 100%
  background-color: rgba(white, 0.05)

  @media only screen and (max-width: 1100px)
    width: 48%
    border-radius: 10px
    svg
      width: 25px
      height: 13px

.title
  color: white
  font-size: 15px
  font-weight: 500
  line-height: 16px

  @media only screen and (max-width: 768px)
    font-size: 9px
    line-height: 9px



