@import "src/styles/colors"
.orderButton
  height: 45px
  display: flex
  align-items: center
  justify-content: center
  background-color: $gray-F5
  border-radius: 12px
  font-weight: 600
  font-size: 13px
  line-height: 16px