@import "src/styles/colors"

@mixin swiper-button
  width: 26px
  height: 43px
  background-color: $light-pink
  z-index: 10
  border-radius: 10px
  position: absolute
  top: 55%
  cursor: pointer

@mixin swiper-button-after
  content: ''
  border: solid $pink-FF
  border-width: 0 3px 3px 0
  display: inline-block
  padding: 3px
  position: absolute
  top: 17px


.portfolio
  display: flex
  flex-direction: column
  gap: 20px
  position: relative

  //\:global .swiper-slide
  //  width: 95%

  \:global .swiper
    position: static

  \:global .swiper-button-prev
    @include swiper-button
    left: 2.5%
    @media only screen and (max-width: 1400px)
      left: 1%
    @media only screen and (max-width: 768px)
      display: none

  \:global .swiper-button-prev::after
    @include swiper-button-after
    transform: rotate(135deg)
    right: 8px

  \:global .swiper-button-next
    @include swiper-button
    right: 2.5%
    @media only screen and (max-width: 1400px)
      right: 1%
    @media only screen and (max-width: 768px)
      display: none

  \:global .swiper-button-next::after
    @include swiper-button-after
    transform: rotate(-45deg)
    right: 10px

  \:global .swiper-button-disabled
    background-color: rgba(black, 0.04)
  \:global .swiper-button-disabled::after
    border: solid $black-75
    border-width: 0 3px 3px 0

  \:global .swiper-pagination-horizontal
    position: absolute
    width: 100%
    left: 0
    bottom: -25px
    height: 10px
    display: flex
    justify-content: center
    gap: 5px

  \:global .swiper-pagination-bullet
    width: 8px
    height: 8px
    border-radius: 50%
    display: flex
    background-color: $pink-FFE
    cursor: pointer

  \:global .swiper-pagination-bullet-active

    background-color: $pink-FF

.wrap
  width: 90%
